.projects {
    width: 100vw;
}
.projects h1{
    color: white;
    font-size: 2.5rem;
}
.project-container{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 4% 0;
}
.project-list{
    box-sizing: border-box;
    border-radius: 24px;
    background: rgba(0, 0, 0);
    display: block;
    align-items: center;
    width: 40%;
    min-width: 400px;
    margin: 10px 5%;
    padding: 2% 1%;
}
.project-description {
    text-align: left;
    padding: 3%;
}
.project-item-name {
    font-size: 1.1rem;
    font-weight: bold;
    padding: 3%;
    text-align: center;
}
.project-item-name a {
    font-weight: lighter;
}
.project-item img {
    width: 80%;
    padding: 5px;
    border-radius: 12px;
}

@media only screen and (max-width: 768px) {
    .project-list {
        width: 350px;
        min-width: 300px;
      }
}