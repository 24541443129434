@import url('https://fonts.googleapis.com/css2?family=Maven+Pro:wght@500&family=Open+Sans&display=swap');

.App {
  text-align: center;
}

body {
  text-align: center;
  margin: 0;
  padding: 0;
  font-family: 'Open Sans';
  background: black;
  color: white;
}

a {
  text-decoration: none;
  color: white;
}