.word span {
    color: white;
    position: relative;
  }
  
  .word span::after {
    content: '';
    width: 2px;
    height: 100%;
    display: block;
    position: absolute;
    right: 0px;
    top: 0;
    animation: blink 0.5s ease infinite alternate-reverse;
  }
  
  @keyframes blink {
    from {
      opacity: 100%;
    }
  
    to {
      opacity: 0%;
    }
  }
  
  .word.empty {
    visibility: hidden;
  }
  
  .word.empty span::after {
    visibility: visible;
    right: 0;
  }