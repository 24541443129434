.skills {
    background: white;
    color: black;
    padding: 10px;
}

.skills h1{
    font-size: 2.5rem;
 }
  
.iframe-container {
    position: relative;
    overflow: hidden;
}
  
.iframe-container iframe {
    width: 65%;
    height: 660px;
    overflow: hidden;
}
  
.relExp p, .prevExp p {
    text-align: left;
}
  
.skill-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin: 10px;
}

.skill h3 {
    font-weight: lighter;
    margin: auto;
}

@media only screen and (max-width: 768px) {
    .skill-list h3 {
        font-size: smaller;
      }
}